import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import de from './locale/de.json'
import en from './locale/en.json'
import getPageLanguage from "./helpers/getPageLanguage";

i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        lng: getPageLanguage(),
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {...en},
            de: {...de},
        },
    });

export default i18n;
