import React, {Suspense} from 'react';
import {GlobalStyle} from "./GlobalStyle";

function App( {type, innerHTML} ) {
  let LazyComponent = null;
  switch ( type ) {
    case 'cookie-layer':
      LazyComponent = React.lazy( () => import('./components/cookie-layer/CookieLayer') );
      break;
    case 'questionnaire':
    case 'pagination':
      LazyComponent = React.lazy( () => import('./components/QuestionnaireApp') );
      break;
    case 'admin':
      LazyComponent = React.lazy( () => import('./components/admin/AdminApp') );
      break;
    default:
      return ( <div>{type} not found</div> );
  }

  return (
    <Suspense fallback="...is loading">
      <GlobalStyle/>
      <LazyComponent innerHTML={innerHTML}/>
    </Suspense>
  );
}

export default App;
