import {createGlobalStyle} from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        font-family: "Outfit", sans-serif;
    }

    :root {
        --wp--preset--color--blue-25: #DDF1FF;
        --wp--preset--color--blue-100: #C8E7FF;
        --wp--preset--color--blue-200: #A3D7FF;
        --wp--preset--color--blue-300: #65BCFF;
        --wp--preset--color--blue-400: #23A0FF;
        --wp--preset--color--blue-500: #007CDA;
        --wp--preset--color--blue-600: #005BA0;
        --wp--preset--color--blue-700: #003963;
        --wp--preset--color--blue-800: #002A4A;
        --wp--preset--color--blue-900: #00213A;
        --wp--preset--color--steel-grey-100: #F9FAFB;
        --wp--preset--color--steel-grey-200: #F0F1F3;
        --wp--preset--color--steel-grey-300: #D0D5DA;
        --wp--preset--color--steel-grey-400: #B1B9C0;
        --wp--preset--color--steel-grey-500: #73818E;
        --wp--preset--color--steel-grey-600: #5E6771;
        --wp--preset--color--steel-grey-700: #4D545B;
        --wp--preset--color--steel-grey-800: #353A40;
        --wp--preset--color--steel-grey-900: #292D32;
        --wp--preset--color--orange-25: #FFF4ED;
        --wp--preset--color--orange-50: #FCD7BC;
        --wp--preset--color--orange-100: #FABC90;
        --wp--preset--color--orange-200: #F7A366;
        --wp--preset--color--orange-300: #F58A3C;
        --wp--preset--color--orange-400: #F47920;
        --wp--preset--color--orange-500: #E5670B;
        --wp--preset--color--orange-600: #CE5C0A;
        --wp--preset--color--orange-700: #B75209;
        --wp--preset--color--orange-800: #8F4007;
        --wp--preset--color--yellow-25: #FFF6DD;
        --wp--preset--color--yellow-100: #FFECBB;
        --wp--preset--color--yellow-200: #FFE090;
        --wp--preset--color--yellow-300: #FFD158;
        --wp--preset--color--yellow-400: #FFC120;
        --wp--preset--color--yellow-500: #E6A600;
        --wp--preset--color--yellow-600: #C99100;
        --wp--preset--color--yellow-700: #AC7C00;
        --wp--preset--color--yellow-800: #815D00;
        --wp--preset--color--error-red-light: #FFF0F1;
        --wp--preset--color--error-red-mid: #FA8686;
        --wp--preset--color--error-red: #EE1D36;
        --wp--preset--color--success-green-light: #EFFDF6;
        --wp--preset--color--success-green-mid: #7FF0B8;
        --wp--preset--color--success-green: #17C970;
        --wp--preset--color--white-shade: #ffffff;
        --wp--preset--color--black-shade: #000000;
    }

    *, :after, :before {
        box-sizing: border-box;
    }
`;

