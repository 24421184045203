import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';

const apps = document.querySelectorAll("[data-fits-app]");

if (apps) {
    apps.forEach((app) => {
        const root = ReactDOM.createRoot(app);
        const appType = app.getAttribute('data-fits-app');
        const innerHTML = app.innerHTML;

        root.render(
            <React.StrictMode>
                <App type={appType} innerHTML={innerHTML}/>
            </React.StrictMode>
        );
    });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
